import React from 'react'

import smartCarsLogo from '../../assets/images/smart-cars-logo.svg'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Config } from '../../Config'

const Footer = () => {
    return (
        <>
            <footer className="text-zinc-400 bg-zinc-900 body-font">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <Link to={"#"} className="flex title-font font-medium items-center md:justify-start justify-center text-white">
                            <img src={smartCarsLogo} className='max-w-12' alt="" />
                            <span className="ml-3 text-xl logo">SMART <span className='text-maincolor'>CARS</span></span>
                        </Link>
                        <p className="mt-2 text-sm text-zinc-500">Samochody z polskich salonów</p>
                    </div>
                    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">OFERTA</h2>
                            <nav className="list-none mb-10">
                            <HashLink to={'/#aukcje'}><li className="text-zinc-400 hover:text-white">Aukcje aut z PL</li></HashLink>
                                <HashLink to={'/flota'}><li className="text-zinc-400 hover:text-white">Auta na sprzedaż</li></HashLink>
                            </nav>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">KONTAKT</h2>
                            <nav className="list-none mb-10">
                                <li className="text-zinc-400 hover:text-white">tel: 537 682 237</li>
                                <li className="text-zinc-400 hover:text-white">email: kontakt@smart-cars.pl</li>
                                <li className="text-zinc-400 hover:text-white">adres: ul. Mogilska 120<br/>31-075 Kraków</li>
                            </nav>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">JESTEŚMY NA</h2>
                            <nav className="list-none mb-10">
                                <HashLink target="_blank" to={Config.socialMedia.facebook}><li className="text-zinc-400 hover:text-white">Facebook</li></HashLink>
                                <HashLink target="_blank" to={Config.socialMedia.instagram}><li className="text-zinc-400 hover:text-white">Instagram</li></HashLink>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="bg-zinc-800 bg-opacity-75">
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-zinc-400 text-sm text-center sm:text-left">&copy; 2024 Smart-Cars.pl</p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <Link to={"#"} className="text-zinc-400">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </Link>
                            <Link to={"#"} className="ml-3 text-zinc-400">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </Link>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer