import React, { useEffect, useState } from 'react';
import SectionHeader from '../sectionHeader/SectionHeader';

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => { setOpenIndex(0) }, [])

    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12" id="faq">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <SectionHeader
                    icon={`<svg class="w-9 mr-4" fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,1A11,11,0,1,0,23,12,11.013,11.013,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9.011,9.011,0,0,1,12,21Zm1-4.5v2H11v-2Zm3-7a3.984,3.984,0,0,1-1.5,3.122A3.862,3.862,0,0,0,13.063,15H11.031a5.813,5.813,0,0,1,2.219-3.936A2,2,0,0,0,13.1,7.832a2.057,2.057,0,0,0-2-.14A1.939,1.939,0,0,0,10,9.5,1,1,0,0,1,8,9.5V9.5a3.909,3.909,0,0,1,2.319-3.647,4.061,4.061,0,0,1,3.889.315A4,4,0,0,1,16,9.5Z"></path></g></svg>`}
                    content={"Często zadawane pytania"} subContent={"Znajdź odpowiedzi na najczęściej zadawane pytania i dowiedz się więcej! Szybkie i pomocne wskazówki czekają na Ciebie w tej sekcji FAQ."} />
                <div className="mx-auto max-w-screen-md flex flex-col border-t">
                    {faqData.map((faq, index) => (
                        <div key={index} className="border-b">
                            <div
                                className="flex cursor-pointer justify-between gap-2 py-4 text-black hover:text-maincolor active:text-maincolor"
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className={`font-semibold transition duration-100 md:text-lg ${openIndex === index ? 'text-maincolor' : 'text-zinc-900'}`}>{faq.question}</span>
                                <span className={`transform transition-transform duration-300 ${openIndex === index ? 'rotate-180' : 'rotate-0'} text-zinc-900`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </span>
                            </div>
                            <div
                                className={`overflow-hidden ${openIndex === index ? 'max-h-auto' : 'max-h-0'}`}
                            >
                                <p className="mb-4 text-gray-500">{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const faqData = [
    {
        question: '1. DLACZWGO WARTO KUPOWAĆ OD SMART CARS?',
        answer:
            'W przeciwieństwie do większości firm handlujących w Polsce, nie skupiamy się na sprzedaży dużej ilości samochodów, wierzymy w jakość, a nie ilość. W SmartCars możemy zaopatrzyć się w każdy rodzaj samochodu. Skupiamy się głównie na świeżych, bezwypadkowych autach z niskimi przebiegami i prawie nowych samochodach. Oferujemy usługi na najwyższym poziomie, które obejmują inspekcje aukcji osobistych, pomoc online, analizę cen rynkowych oraz pełny dostęp do aukcji dla wszystkich naszych klientów. Dostarczamy informacje potrzebne naszym klientom do podjęcia świadomej decyzji',
    },
    {
        question: '2. JAKI JEST STAN AUT Z AUKCJI?',
        answer:
            'Krajowe, po pierwszym właścicielu aukcje, w przeciwieństwie do amerykańskich, wystawiają samochody sprawne, bezwypadkowe i nieuszkodzone. Jeśli pojazd nie odpowiada deklarowanym informacją w raporcie mamy prawo nie odebrać samochodu, jednak z praktyki możemy śmiało stwierdzić że jest to bardzo rzadki przypadek. Każdy samochód przed wystawieniem na aukcje jest dokładnie sprawdzany przez niezależne firmy, Dekra, Macadam itp…',
    },
    {
        question: '3. JAK DŁUGO TRWA CAŁY PROCES?',
        answer:
            'Wszystko zależy od wyszukania odpowiedniego pojazdu do podjęcia licytacji względem specyfikacji pojazdu wybranego przez klienta. Z doświadczenia możemy powiedzieć że do 14 dni z dostarczeniem samochodu do Krakowa. Bywały przypadki że proces cały zamykał się w 5 dniach i klient wracał do domu „nowym” samochodem.',
    },
    {
        question: '4. DLACZEGO KUPOWAĆ KRAJOWE AUTA?',
        answer: 'Przede wszystkim przejrzysta historia, pojazd miał lakierowany zderzak po tak zwanej przecierce parkingowej bądź wymienianą szybę po odprysku – wszystko jest w historii i każda naprawa jest wykonana w ASO z pełną dokumentacja. Kolejnym plusem samochodu z Polskiej sieci dealerskiej jest bezwypadkowość, wstawiona ćwiartka, poszycie, wymienione pół przodu – to nie Ameryka. Każdy samochód jest w 100% bezwypadkowy z potwierdzonym niskim przebiegiem.',
    },
    {
        question: '5. JAK MOGĘ ZOABCZYĆ OFEROWANE SAMOCHODY?',
        answer: 'Dołącz do naszych social mediów, codziennie wrzucane propozycje licytacji. Masz wybrany samochód? Zadzwoń lub napisz omówimy wszystko i wyślemy proponowane aukcje.',
    },
    {
        question: '6. CO MUSZĘ ZROBIĆ, ŻEBY KUPIĆ SAMOCHÓD?',
        answer: 'Na naszej stronie wyjaśniono jak kupować (wymagane kroki). Każdy, kto jest dealerem lub indywidualnym nabywcą, może dokonać u nas zakupu.',
    },
    {
        question: '7. JAKIE METODY PŁATNOŚCI SĄ AKCEPTOWANE?',
        answer: 'Po wystawieniu FAKTURY VAT: - Tradycyjny przelew na nasze konto - Gotówka',
    },
    {
        question: '8. SKĄD KUPUJECIE DLA MNIE SAMOCHODY?',
        answer: 'Mamy dostęp do wielu, wiodących i czołowych platform z aukcjami samochodów osobowych i dostawczych. Codziennie po 100 ogłoszeń aut do licytacji.',
    },
    {
        question: '9. ILE POBIERACIE PROWIZJI ZA POŚREDNICTWO?',
        answer: 'Zapraszamy do kontaktu :)',
    },
];

export default Faq;
