export const Config = {
    contact: {
        phone: "(+48) 537 682 237",
    },
    socialMedia: {
        facebook: "https://www.facebook.com/smartcarspl",
        instagram: "https://www.instagram.com/smart_cars.pl/profilecard/?igsh=dHVkazRudXkzcTI%3D",
        whatsapp: "https://wa.me/+48537682237"
    }
}

