import React from 'react'
import Header from '../components/header/Header'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'
import Content from '../components/content/Content'
import HeroSmall from '../components/hero/HeroSmall'

const AboutUs = () => {
    return (
        <>
            <Header />
            <HeroSmall props={{
                sizeOfHero: "50vh"
            }} />
            <Content />
            <Contact />
            <Footer />
        </>
    )
}

export default AboutUs