import React, { useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';

import AOS from "aos";
import "aos/dist/aos.css";

import Home from './pages/Home';
import Error from './pages/Error';
import AboutUs from './pages/AboutUs';
import Fleet from './pages/Fleet';

const App = () => {

  document.title = "Smart-Cars.pl - Krajowe Samochody z polskich salonów";

  const location = useLocation();

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    AOS.init();
  }, []);

  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/onas', element: <AboutUs /> },
    { path: '/flota', element: <Fleet /> },
    { path: '*', element: <Error /> },
  ]);

  return routes;
}

export default App;
