import React from 'react';
import SectionHeader from '../sectionHeader/SectionHeader';

const Features = ({ props }) => {

    const { data, header, subHeader, styleConfig, icon } = props;
    let delay = 0;

    return (
        <section
            style={{
                textAlign: styleConfig.contentCenter ? "center" : undefined, // Conditional text alignment
                ...(styleConfig.backgroundImage.use && { // Conditionally apply background styles
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.0) 100%), url(${styleConfig.backgroundImage.url})`,
                    backgroundPosition: 'right',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                })
            }}
            className="text-gray-600 body-font" id="aukcje"
        >
            <div className="container px-5 py-24 mx-auto">
                <SectionHeader content={header} subContent={subHeader} icon={icon} />
                <div className="flex flex-wrap -m-4">

                    {/* iterate */}
                    {data?.map((item, key) => {
                        delay += 100;
                        return (
                            <div data-aos="fade-up" data-aos-delay={delay} className="p-4 md:w-1/2 lg:w-1/3" key={key}>
                                <div className={`backdrop-blur-md flex h-full p-8 flex-col bg-white/[0.5] ${styleConfig.hoverEffect ? " hover:shadow-md transition-shadow " : ""} `}>
                                    <div 
                                    style={{
                                        justifyContent: styleConfig.contentCenter ? "center" : undefined,
                                    }}
                                    className="flex items-center mb-3">
                                        <div 
                                        className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-maincolor text-white flex-shrink-0">
                                            <span
                                                dangerouslySetInnerHTML={{ __html: item.icon }}
                                            />
                                        </div>
                                        <h2 className="text-gray-900 text-lg title-font font-medium">{item.header}</h2>
                                    </div>
                                    <div className="flex-grow">
                                        <p className="leading-relaxed text-base text-black">{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {/* iterate */}

                </div>
            </div>
        </section>
    );
}

export default Features;
