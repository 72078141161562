import React from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../sectionHeader/SectionHeader'

const Contact = () => {
    return (
        <>
            <section id="kontakt" className="text-zinc-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <SectionHeader content={"Kontakt"} subContent={"Zapraszamy do skontaktowania się z nami! Jesteśmy gotowi odpowiedzieć na wszelkie pytania i zapewnić kompleksową pomoc. Czekamy na twój e-mail lub wiadomość. Jesteśmy tu, abyś mógł skorzystać z naszych usług w pełni. Nie wahaj się skontaktować - Twoja satysfakcja to nasz priorytet!"} />
                    <div className="flex flex-wrap items-stretch -m-4 text-center xl:px-48">
                        <div className="p-4 w-full lg:w-1/3 mx-auto flex">
                            <Link to={"tel:537682237"} className="w-full h-full">
                                <div className="bg-zinc-50 px-4 py-6 cursor-pointer hover:shadow-md transition-shadow border border-zinc-100 flex flex-col h-full">
                                    <div className="flex items-center justify-center mb-3">
                                        <svg className="w-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z" stroke="#bd943f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <h2 className="min-h-[64px] title-font font-semibold text-[7vw] sm:text-[3vw] md:text-[3vw] lg:text-2xl text-zinc-700">537 682 237</h2>
                                    <p className="leading-relaxed logo text-sm mt-4">SMART <span className='text-maincolor'>CARS</span></p>
                                </div>
                            </Link>
                        </div>

                        <div className="p-4 w-full lg:w-1/3 mx-auto flex">
                            <Link to={"mailto:kontakt@smart-cars.pl"} className="w-full h-full">
                                <div className="bg-zinc-50 px-4 py-6 cursor-pointer hover:shadow-md transition-shadow border border-zinc-100 flex flex-col h-full">
                                    <div className="flex items-center justify-center mb-3">
                                        <svg className="w-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16V16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21H16" stroke="#bd943f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <h2 className="min-h-[64px] title-font font-semibold text-[7vw] sm:text-[3vw] md:text-[3vw] lg:text-2xl text-zinc-700">kontakt@smart-cars.pl</h2>
                                    <p className="leading-relaxed logo text-sm mt-4">SMART <span className='text-maincolor'>CARS</span></p>
                                </div>
                            </Link>
                        </div>

                        <div className="p-4 w-full lg:w-1/3 mx-auto flex">
                            <Link to={"https://maps.app.goo.gl/cQ5QqULtWBrNPWKG6"} target='_blank' className="w-full h-full">
                                <div className="bg-zinc-50 px-4 py-6 cursor-pointer hover:shadow-md transition-shadow border border-zinc-100 flex flex-col h-full">
                                    <div className="flex items-center justify-center mb-3">
                                        <svg className="w-12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="#bd943f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#bd943f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <h2 className="min-h-[64px] title-font font-semibold text-[7vw] sm:text-[3vw] md:text-[3vw] lg:text-2xl text-zinc-700">ul. Mogilska 120<br />31-075 Kraków</h2>
                                    <p className="leading-relaxed logo text-sm mt-4">SMART <span className='text-maincolor'>CARS</span></p>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Contact