import React from 'react'

const SectionHeader = ({ content, subContent, icon }) => {

    return (
        <div className="flex flex-wrap flex-col mb-10 md:mb-16">
            <h2 className="flex items-center sm:text-3xl text-2xl font-medium title-font text-white px-4 py-2 uppercase bg-zinc-900 mx-auto mb-4">
                {icon ? <span dangerouslySetInnerHTML={{ __html: icon }}></span> : <svg className="w-9 h-9 mr-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="2" stroke="#fff" strokeWidth="1.5"></circle> <path d="M6 12L10 12" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M14 12L18 12" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M9 17.1963L11 13.7322" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M13 10.2681L15 6.80396" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M15 17.1963L13 13.7322" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M11 10.2681L9 6.80396" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M15 17.1973C14.1175 17.7078 13.0929 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 13.0929 17.7078 14.1175 17.1973 15" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>}
                {content}
            </h2>
            <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                {subContent}
            </p>
        </div>

    )
}

export default SectionHeader