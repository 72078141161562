import React from 'react'
import Collections from '../components/collections/Collections'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import HeroSmall from '../components/hero/HeroSmall'

const Fleet = () => {
    return (
        <>
            <Header />
            <HeroSmall props={{
                sizeOfHero: "50vh"
            }} />
            <Collections props={{
                header: "Auta na sprzedaż",
                data: [
                    {
                        id: "1",
                        car: "BMW 3GT xDrive 3.0i",
                        image: "https://demo.smart-cars.ct8.pl/upload/BMW%203GT%20xDrive%2030iJPG.jpg",
                        price: "112 900",
                        carDetail: {
                            engine: "",
                            hp: "",
                            year: "2020"
                        }
                    },
                    {
                        id: "2",
                        car: "BMW X1 sDrive 1.8i",
                        image: "https://demo.smart-cars.ct8.pl/upload/BMW%20X1%20sDrive%2018iJPG.jpg",
                        price: "124 900",
                        carDetail: {
                            engine: "",
                            hp: "",
                            year: "2021"
                        }
                    }
                ]
            }} />
            <Collections props={{
                header: "Auta sprzedane",
                data: [
                    {
                        "id": "9",
                        "car": "BMW 5/G30 xDrive 2.0d",
                        "image": "https://demo.smart-cars.ct8.pl/upload/BMW%205G30%20xDrive%202.0d.jpg",
                        "price": "158 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2020"
                        }
                    },
                    {
                        "id": "8",
                        "car": "BMW 5/G31 xDrive 2.0d",
                        "image": "https://demo.smart-cars.ct8.pl/upload/IMG_6065JPG.jpg",
                        "price": "162 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2021"
                        }
                    },
                    {
                        "id": "7",
                        "car": "BMW X2 sDrive 1.8i",
                        "image": "https://demo.smart-cars.ct8.pl/upload/BMW%20X2%20sDrive%201.8i.jpg",
                        "price": "112 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2019"
                        }
                    },
                    {
                        "id": "6",
                        "car": "Volvo S60 T4 2.0",
                        "image": "https://demo.smart-cars.ct8.pl/upload/Volvo%20S60%20T4%202.0.jpg",
                        "price": "142 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2020"
                        }
                    },
                    {
                        "id": "5",
                        "car": "BMW X3 xDrive 2.0i",
                        "image": "https://demo.smart-cars.ct8.pl/upload/BMW%20X3%20xDrive%202.0i.jpg",
                        "price": "169 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2021"
                        }
                    },
                    {
                        "id": "4",
                        "car": "BMW seria 2 Active Tourer",
                        "image": "https://demo.smart-cars.ct8.pl/upload/BMW%20seria%202%20Active%20Tourer.jpg",
                        "price": "154 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2022"
                        }
                    },
                    {
                        "id": "3",
                        "car": "MINI COOPER 1.8i Sport",
                        "image": "https://demo.smart-cars.ct8.pl/upload/MINI%20COOPER%201.8i%20Sport.jpg",
                        "price": "89 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2020"
                        }
                    },
                    {
                        "id": "2",
                        "car": "BMW X1 xDrive 2.0i",
                        "image": "https://demo.smart-cars.ct8.pl/upload/BMW%20X1%20xDrive%202.0i.jpg",
                        "price": "149 900",
                        "carDetail": {
                            "engine": "",
                            "hp": "",
                            "year": "2020"
                        }
                    },
                    {
                        "id": "1",
                        "car": "BMW F36 seria 4",
                        "image": "https://demo.smart-cars.ct8.pl/upload/bmw1.jpg",
                        "price": "98 000 zł",
                        "carDetail": {
                            "engine": "xDrive 2.0d",
                            "hp": "",
                            "year": "2015"
                        }
                    }
                ]
            }} />
            <Footer />
        </>
    )
}

export default Fleet